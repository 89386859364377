import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Container, Row } from "reactstrap"
import Layout from "../components/Layout.js"
 
const AboutPage = () => {
  return (
        <Layout>
            <title>О компании</title>
            <Container>
                <h1 className="text-page-heading">
                    <span>Ohm Electronics — </span>если устройство нельзя отремонтировать — это не значит, что его нельзя изготовить заново
                </h1>
            </Container>
            <Container fluid className="text-page-photo">
                <Row>
                    <StaticImage src="../images/1.jpg" alt="О компании" className="text-page-photo-image" />
                </Row>
            </Container>
            <Container className="text-page-text">
                <p>
                Электроника, применяемая при изготовлении промышленного оборудования, в силу специфики стоящих перед ней задач, разрабатывается и изготавливается с учетом повышенных требований к сроку эксплуатации, качеству компонентов и стабильности работы. Ремонтные работы в области промышленной электроники, относятся к высшей категории сложности, требуют отличного знания электроники, высокой инженерной квалификации, значительного опыта и умения диагностировать неисправность, часто при отсутствии принципиальных электрических схем. Обязательным, при этом, является наличие соответствующего контрольно-измерительного оборудования и ремонтной оснастки (иногда нестандартной).
                </p><p>
                Большинству производителей промышленного оборудования гораздо выгоднее, чтобы вы заменили вышедший из строя узел или блок целиком. Между тем, стоимость замены транзистора или микросхемы на печатной плате, на порядок (зачастую больше) дешевле замены всего узла или блока целиком. Довольно часто бывает, что найти производителя не представляется возможным. Разумеется, при таком положении дел, конечный потребитель оборудования будет искать возможность отремонтировать неисправный блок, а не менять его целиком. Именно мы, эту возможность предоставляем...
                </p><p>
                Специалисты нашей компании имеют необходимые квалификацию и опыт работы, для ремонта и разработки самого сложного электронного оборудования. Собственный парк станков, диагностического и паяльного оборудования позволяют выполнить полный цикл работ не только по ремонту, но и по штучному производству электронных плат и блоков, от разработки принципиальной электрической схемы до создания готового изделия в корспусе, со всеми необходимыми установочными и декоративными элементами, информационными и предупреждающими шильдами и трафаретами. 
                </p><p>
                Применение современных программных комплексов и CAD систем, позволяет подготовить полный комплект технической документации в соответствии с ГОСТ отвечающим стандартам ЕСКД.
                </p><p>
                Наличие договоров с крупными поставщиками электронных компонентов, позволяет в кратчайшие сроки осуществлять поиск и закупку необходимых комплектующих, в том числе редких комплектующих с брокерских складов производство которых в настоящее время не производится.
                </p><p>
                В случае необходимости можем произвести подбор современных аналогов устаревшей элементной базы, адаптировать к существующим требованиям обмен и сбор информации. Большой опыт использования микроконтроллеров и ПЛИС ведущих мировых и отечественных произодителей позволяет ремонтировать и разрабатывать пульты и панели управления, интегрировать в существующую систему сбора и обмена информации современные ПК под управлением Linux, Windows, Mac OS. 
                </p>
            </Container>
      </Layout>
  )
}

export default AboutPage
